.crisisChecker {
    min-height: 100vh;
    background-color: #000;
    color: #ffffff;
}

.title {
    margin-bottom: 3.5rem;
    font-size: 3.5rem;
}

.button {
    font-size: 2rem;
    padding: 0.5rem 3rem;
    border-radius: 0.5rem;
    background-color: #343a40;
    border: none;
    transition: background-color 0.6s ease, color 0.6s ease;
    width: 180px;

    &:hover {
        background-color: lighten($color: #e00e34, $amount: 10%);
    }
}